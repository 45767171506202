import "./App.css";
import "tailwindcss/tailwind.css";
import Home from "./Screen/Home";
function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
